.cameraDetailPage {
    height: 100%;
    display: grid;
    overflow: visible;
    grid-template-areas: 
        "camera-selection time-selection"
        "camera-title camera-title"
        "camera-carousel camera-graph"
        "camera-carousel camera-cross-view"
        "camera-controls camera-cross-view";
    grid-template-columns: minmax(min-content, 1fr) 1fr;
    gap: 30px;
    justify-content: space-evenly;
    align-items: start;
}

.timeSelection {
    grid-area: time-selection;
    justify-self: end;
    display: flex;
    flex-direction: row;
    gap: 10px;
}

.cameraDetailButtons {
    grid-area: camera-controls;
    display: flex;
    flex-direction: row;
    gap: 10px;
}
