.imageWithTimestampContainer {
    position: relative;
    width: 100%;
    aspect-ratio: 16 / 9;
    height: inherit;
}

.carouselImage {
    width: 100%;
    height: 100%;
    object-fit: contain;
}