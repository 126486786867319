.cameraSettingsPage {
    display: grid;
    grid-template-areas: 
        "camera-selection camera-location"
        "camera-info-title youtube-info-title"
        "camera-info youtube-info"
        "camera-info edgematrix-info-title"
        "camera-info edgematrix-info"
        "camera-info camera-info-controls";
    grid-template-rows: repeat(5, auto) 1fr;
    grid-template-columns: 1fr 1fr;
    gap: 15px;
    align-content: start;
}

.inputMenu {
    display: flex;
    flex-flow: row;
    width: 100%;
    height: min-content;
    padding: 15px;
    gap: 10px;

    background-color: #fef9e7;
    border-radius: 5px;
}

.inputMenuLabel {
  display: flex;
  justify-content: start;
  align-items: center;
  flex-grow: 1;
  padding-left: 10px;
  width: 10%;
}

.controlButtons {
    display: flex;
    flex-flow: row;
    justify-content: flex-end;
    align-items: flex-end;
    gap: 10px;
}
