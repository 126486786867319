.cameraLocationDialog {
    display: grid;
    grid-template-areas: 
        "help ."
        "map-cameras icons"
        "map-cameras confirm";
    grid-template-columns: 1fr auto;
    grid-template-rows: auto 1fr auto;
    justify-content: space-evenly;
    gap: 25px;
    background-color: #E5E7E9;

    /* to remove mui style */
    padding: 20px !important;
}

.cameraLocationHelp {
    display: flex;
    border-radius: 4px;
    background-color: white;
    padding: 5px;
    align-items: center;
    gap: 10px;
}

.cameraLocationIcons {
    grid-area: icons;
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-content: start;
    gap: 5px;
}
