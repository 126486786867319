body {
    margin: 0px;
    padding: 0px;
    height: 100vh;
    width: 100vw;
    background-color: #E5E7E9;
    font-family: 'Meiryo', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

#root {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}
