.cameraListPage {
    display: grid;
    overflow: auto;
    height: 100%;
    grid-template-areas: 
        "map-title map-title"
        "map-cameras camera-table";
    grid-template-rows: auto 1fr;
    grid-template-columns: repeat(2, minmax(auto, 1fr));
    gap: 25px;
    justify-content: space-evenly;
    align-content: start;
    margin-bottom: 20px;
}
