.cameraAllPage {
    display: grid;
    grid-template-areas:
        "page-title pagination pagination empty";
    grid-template-columns: repeat(4, 1fr);
    height: 100%;
    overflow: auto;
    gap: 25px;
    justify-content: space-evenly;
    align-content: start;
}
